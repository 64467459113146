/* app/ui/track */

var TrackLoad = {
	init: function () {
		if (document.querySelector('.js-track__umbraco-form')) {
			import(/* webpackChunkName: "TrackUmbracoForm" */ 'App/track/umbraco-form').then(({ Track: UmbracoForm }) => {
				UmbracoForm.init();
			});
		}

		if (document.querySelector('.js-track__book, .js-track__mobile-book')) {
			import(/* webpackChunkName: "TrackMotionSolution" */ 'App/track/motion-solution').then(({ Track: MotionSolution }) => {
				MotionSolution.init();
			});
		}
	}
};

export { TrackLoad };
