/* app/ui/video/common */

import $ from 'jquery';
import { publish } from 'Util/pubsub';

var VideoCommon = {
	init: function() {
		VideoCommon._initVideoEvent();
	},

	_initVideoEvent: function() {
		var $videos = $('.js-video');
		var videoLoaded = $videos.data('video.loaded');

		if (!videoLoaded) {
			$videos.data('video.loaded', 'true').on('click', this._handleClick);
		}
	},

	_handleClick: function(event) {
		event.preventDefault();
		var $thisVideo = $(this);

		publish('/video/playing', [{ videoid: $thisVideo.attr('data-video-id') }]);
	}
};

export { VideoCommon };
