import $ from 'jquery';
import { fileIO } from 'Util/fileio';
import { publish } from 'Util/pubsub';

var selectors = {
	resourceItem: '.js-resources__item',
	resourceDownload: '.js-resources__download',

	resourceFileContainer: '.js-resources__file-container',
	resourceFile: '.js-resources__file',
	resourceFileType: '.js-resources__file-type',

	resourceTitle: '.js-resources__title'
};

var dataSelectors = {
	resourceDownloadError: 'resources-download-error'
};

/*
	<div class="js-resources__item" data-resources-download-error="Please try again later">
		<span class="c-resources__title js-resources__title">@cadDocument.Name</span>

		<ul>
			<li class="js-resources__file-container">
				<label>
					<input type="checkbox" class="js-resources__file"
					       value="@(cadDocument.Key)|@(cadFile.File.Id)" />

					<span class="js-resources__file-type">@cadFile.Type</span>
				</label>
			</li>
		</ul>

		<button disabled class="js-resources__download">Download</button>
	</div>
*/

var api = {
	download: '/download-zip'
};

var publications = {
	error: '/status/error'
};

var States = {
	ENABLED: 'enabled',
	DISABLED: 'disabled'
};

var module = {
	init: {
		init: function () {
			module.init._initEvents();

			module.init._initButtonStates();
		},

		_initEvents: function () {
			$(selectors.resourceFile).on('change resources/init', module.resources.fileChangeEvent);
			$(selectors.resourceDownload).on('click', module.resources.downloadEvent);
		},

		_initButtonStates: function () {
			// This is only necessary if the use does something
			// before the JS loads that should cause the button states to change

			$(selectors.resourceItem).each(function (i, el) {
				var $item = $(el);
				var $file = $item.find(selectors.file).first();

				$file.trigger('resources/init');
			});
		}
	},

	resources: {
		fileChangeEvent: function (e) {
			var $item = $(e.target).closest(selectors.resourceItem);
			var files = module.resources._getSelectedFiles($item);

			var state = files.length ? States.ENABLED : States.DISABLED;

			module.resources._setDownloadDisabledState($item, state);
		},


		downloadEvent: function (e) {
			e.preventDefault();

			var $item = $(e.target).closest(selectors.resourceItem);

			module.resources._download($item);
		},

		_download: function ($item) {
			var files = module.resources._getSelectedFiles($item);
			var mediaIds = module.resources._getMediaIdList(files);

			var request;
			var filename = module.resources._getFilename($item);

			$item.attr('aria-busy', true);
			module.resources._setDownloadDisabledState($item, States.DISABLED);
			module.resources._setFileDisabledState($item, States.DISABLED);

			// jQuery doesn't handle ajax requests for files well, so use native JS
			request = new XMLHttpRequest();
			request.open('GET', api.download + '/' + mediaIds, true);
			request.responseType = 'arraybuffer';

			request.onload = module.resources._handleDownloadResponse(request, $item, filename);
			request.send();
		},

		_handleDownloadResponse: function (request, $item, filename) {
			return function (event) {
				module.resources._downloadComplete($item);

				if (request.status === 200) {
					module.resources._downloadSuccess(filename, request);
				} else {
					module.resources._downloadError($item);
				}
			};
		},

		_downloadComplete: function ($item) {
			$item.attr('aria-busy', false);
			module.resources._setDownloadDisabledState($item, States.ENABLED);
			module.resources._setFileDisabledState($item, States.ENABLED);
		},

		_downloadSuccess: function (filename, request) {
			fileIO.save.data(request.response, filename, 'application/x-zip-compressed');
		},

		_downloadError: function ($item) {
			var errorMessage = $item.data(dataSelectors.resourceDownloadError);

			publish(publications.error, errorMessage);
		},


		_setFileDisabledState: function ($item, state) {
			var $file = $item.find(selectors.resourceFile);

			if (state === States.ENABLED) {
				$file.prop('disabled', false);
			} else if (state === States.DISABLED) {
				$file.prop('disabled', true);
			}
		},

		_setDownloadDisabledState: function ($item, state) {
			var $download = $item.find(selectors.resourceDownload);

			if (state === States.ENABLED) {
				$download.prop('disabled', false);
			} else if (state === States.DISABLED) {
				$download.prop('disabled', true);
			}
		},

		_getSelectedFiles: function ($item) {
			var $files = $item.find(selectors.resourceFile);
			var $selectedFiles = $files.filter(':checked');

			var ids = [];

			var i;
			var $file;
			var id;

			for (i = 0; i < $selectedFiles.length; i++) {
				$file = $selectedFiles.eq(i);
				id = $file.val();

				ids.push(id);
			}

			return ids;
		},

		_getFilename: function ($item) {
			var $name = $item.find(selectors.resourceTitle);
			var name = $name.text().trim();

			var $files = $item.find(selectors.resourceFile);
			var $selectedFiles = $files.filter(':checked');
			var $selectedFileContainers = $selectedFiles.closest(selectors.resourceFileContainer);
			var $selectedFileTypes = $selectedFileContainers.find(selectors.resourceFileType);

			var selectedFileTypes = [];
			var type;
			var i;

			var filename;

			for (i = 0; i < $selectedFileTypes.length; i++) {
				type = $selectedFileTypes.eq(i).text().trim();

				selectedFileTypes.push(type);
			}

			filename = name + ' ' + selectedFileTypes.join(', ') + '.zip';

			return filename;
		},

		_getMediaIdList: function (files) {
			// Convert combination IDs for CAD files into only the media ID
			var i;
			var id;
			var idParts;
			var mediaIds = [];

			for (i = 0; i < files.length; i++) {
				id = files[i];
				mediaIds.push(id);
			}

			return mediaIds;
		}
	}
};

var resources = {
	init: module.init.init
};

export { resources };
