/* Pubsub v1.1 */

var pubsub = (function () {
	var subscriptions = {};

	var module = {
		publish: function (event, args) {
			var callbacks;

			args = Array.prototype.slice.call(arguments, 1);

			// If only one additional argument is passed through,
			// but it is an array, treat it as an array of arguments.
			// This is for backwards compatibility with the old jQuery pubsub
			if (args.length === 1 && Array.isArray(args[0])) {
				args = args[0];
			}

			if (event in subscriptions) {
				callbacks = subscriptions[event];

				callbacks.forEach(function (callback) {
					callback.apply(null, args);
				});
			}
		},

		subscribe: function (event, callback) {
			var events = event.split(' ');
			var callbacks;

			if (events.length > 1) {
				events.forEach(e => module.subscribe(e, callback));
				return;
			}

			if (!(event in subscriptions)) {
				subscriptions[event] = [];
			}

			callbacks = subscriptions[event];

			// Don't bind a particular function to an event more than once
			if (callbacks.indexOf(callback) === -1) {
				callbacks.push(callback);
			}
		},

		unsubscribe: function (event, callback) {
			var events = event.split(' ');
			var callbacks;
			var index;

			if (events.length > 1) {
				events.forEach(e => module.unsubscribe(e, callback));
				return;
			}

			if (event in subscriptions) {
				callbacks = subscriptions[event];
				index = callbacks.indexOf(callback);

				if (index !== -1) {
					callbacks.splice(index, 1);
				}
			}
		}
	};

	return {
		publish: module.publish,

		subscribe: module.subscribe,
		unsubscribe: module.unsubscribe
	};
})();

var publish = pubsub.publish;
var subscribe = pubsub.subscribe;
var unsubscribe = pubsub.unsubscribe;

export { publish, subscribe, unsubscribe };
