/* app/ui/expand-collapse/expand-collapse */

import $ from 'jquery';
import Velocity from 'velocity-animate';

import { activate } from 'Util/activate';
import { publish, subscribe } from 'Util/pubsub';

/* Expand/Collapse v1.3 */

var ExpandCollapse = (function ($, activate, publish, subscribe) {
	var selectors = {
		item: '.js-expand-collapse',

		toggle: '.js-expand-collapse__toggle, .js-expand-collapse__trigger',
		tab: '.js-expand-collapse__tab',
		open: '.js-expand-collapse__open',
		close: '.js-expand-collapse__close',

		body: '.js-expand-collapse__item-body'
	};
	selectors.trigger = [selectors.toggle, selectors.tab, selectors.open, selectors.close].join(', ');

	var dataSelectors = {
		autofocusId: 'expand-collapse-autofocus',
		// Used to account for CSS animation delaying when an element becomes focusable
		autofocusDelay: 'expand-collapse-autofocus-delay',

		// No more than one item in a set can be opened at a time
		// Items can belong to multiple sets, with set names separated
		// by the "setSeparator" string declared below
		set: 'expand-collapse-set',

		openedText: 'expand-collapse-opened-text',
		closedText: 'expand-collapse-closed-text',

		animation: 'expand-collapse-animation',

		useHidden: 'expand-collapse-use-hidden',
		useCurrent: 'expand-collapse-use-current'
	};

	var setSeparator = ' ';

	var pubsubEvents = {
		open: '/expandcollapse/open',
		opened: '/expandcollapse/opened',

		close: '/expandcollapse/close',
		closed: '/expandcollapse/closed',

		closeSet: '/expandcollapse/close/set', // Close a set
		setClosed: '/expandcollapse/closed/set' // A set has been closed
	};

	var States = {
		INITIAL: 0,
		OPENED: 1,
		CLOSED: 2
	};

	var stateAttributes = {
		expanded: 'aria-expanded',
		hidden: 'aria-hidden',

		current: 'aria-current'
	};

	var ExpandCollapse = {
		init: function () {
			ExpandCollapse._initEvents();
			ExpandCollapse._initSubscriptions();
		},

		_initEvents: function () {
			$(document).on(activate.event, selectors.trigger, activate(ExpandCollapse._processTriggerClick));
		},

		_initSubscriptions: function () {
			subscribe(pubsubEvents.open, ExpandCollapse._open);
			subscribe(pubsubEvents.close, ExpandCollapse._close);
			subscribe(pubsubEvents.closeSet, ExpandCollapse._closeSet);
		},

		_processTriggerClick: function (e) {
			// The expand/collapse trigger for a single item

			e.preventDefault();

			var $trigger = $(e.target).closest(selectors.trigger);
			var $item = ExpandCollapse._getTriggerItem($trigger);

			if ($trigger.is(selectors.toggle)) {
				ExpandCollapse._toggleItem($item);
			} else if ($trigger.is(selectors.tab)) {
				ExpandCollapse._tabItem($item);
			} else if ($trigger.is(selectors.open)) {
				ExpandCollapse._setState($item, States.OPENED);
			} else if ($trigger.is(selectors.close)) {
				ExpandCollapse._setState($item, States.CLOSED);
			}
		},

		_toggleItem: function ($item) {
			// Toggle an item's state between opened and closed
			var state;

			// Detect the current state so we can infer the action from that
			state = ExpandCollapse._getState($item);
			if (state === States.CLOSED || state === States.INITIAL) {
				// Currently closed, so open it
				// Treat unspecified initial states as closed
				ExpandCollapse._setState($item, States.OPENED);
			} else if (state === States.OPENED) {
				// Currently opened, so close it
				ExpandCollapse._setState($item, States.CLOSED);
			}
		},

		_tabItem: function ($item) {
			// Tab an item's state between opened and closed
			var state;

			// Detect the current state so we can infer the action from that
			state = ExpandCollapse._getState($item);
			if (state === States.CLOSED || state === States.INITIAL) {
				// Currently closed, so open it
				// Treat unspecified initial states as closed
				ExpandCollapse._setState($item, States.OPENED);
			}
		},

		_getTriggerItem: function ($trigger) {
			var $item;
			var target = $trigger.attr('aria-controls');

			// If there is an aria-controls attribute, use that
			if (target) {
				$item = $('#' + target);
			}

			// Otherwise, use HTML structure
			if (!($item && $item.length)) {
				$item = $trigger.closest(selectors.item);
			}

			return $item;
		},

		_getItemTriggers: function ($item) {
			var $idTriggers;
			var $childTriggers;
			var $trigger = $();
			var id = $item.attr('id');

			var $triggers;
			var triggerGroups = {
				open: [],
				close: [],
				toggle: []
			};

			// Triggers using the aria-controls attribute
			if (id) {
				$idTriggers = $(selectors.trigger);

				$idTriggers = $idTriggers.filter('[aria-controls="' + id + '"]');
			} else {
				$idTriggers = $();
			}

			// Triggers using HTML structure
			$childTriggers = $item.find(selectors.trigger).filter(function () {
				return $(this).closest(selectors.item).is($item);
			});

			$triggers = $idTriggers.add($childTriggers);

			triggerGroups.all = $triggers;
			triggerGroups.open = $triggers.filter(selectors.open);
			triggerGroups.close = $triggers.filter(selectors.close);
			triggerGroups.toggle = $triggers.filter(selectors.toggle);
			triggerGroups.tab = $triggers.filter(selectors.tab);

			return triggerGroups;
		},

		_getState: function ($item) {
			var usingHidden = $item.data(dataSelectors.useHidden);

			var stateAttr = stateAttributes.expanded;
			var closedVal = 'false';
			var openedVal = 'true';

			if (usingHidden) {
				stateAttr = stateAttributes.hidden;
				closedVal = 'true';
				openedVal = 'false';
			}

			if ($item.attr(stateAttr) === closedVal) {
				// Closed
				return States.CLOSED;
			} else if ($item.attr(stateAttr) === openedVal) {
				// Opened
				return States.OPENED;
			} else {
				// Default/Initial
				return States.INITIAL;
			}
		},

		_open: function ($item) {
			ExpandCollapse._setState($item, States.OPENED);
		},

		_close: function ($item) {
			ExpandCollapse._setState($item, States.CLOSED);
		},

		_setState: function ($item, state) {
			var $body = $item.find(selectors.body);

			var expandedAttr = stateAttributes.expanded;
			var openedVal = 'true';
			var closedVal = 'false';

			var useHidden = $item.data(dataSelectors.useHidden);
			if (useHidden) {
				expandedAttr = stateAttributes.hidden;
				openedVal = 'false';
				closedVal = 'true';
			}

			if (state === States.OPENED) {
				// Close the whole set first
				ExpandCollapse._closeSet($item);

				// Set to opened state
				$item.attr(expandedAttr, openedVal);

				if ($item.data(dataSelectors.animation) === 'slideDown') {
					Velocity($body, 'slideDown', { duration: 250 });
				}

				ExpandCollapse._setStateTriggers($item, state);

				ExpandCollapse._processOpen($item);
				publish(pubsubEvents.opened, [$item, $item.data(dataSelectors.set)]);
			} else if (state === States.CLOSED) {
				// Set to closed state
				$item.attr(expandedAttr, closedVal);

				if ($item.data(dataSelectors.animation) === 'slideDown') {
					Velocity($body, 'slideUp', { duration: 250 });
				}

				ExpandCollapse._setStateTriggers($item, state);

				ExpandCollapse._processClose($item);
				publish(pubsubEvents.closed, [$item, $item.data(dataSelectors.set)]);
			} else if (state === States.INITIAL) {
				// Remove state signifiers
				$item.removeAttr(expandedAttr);

				ExpandCollapse._setStateTriggers($item, state);
			}
		},

		_setStateTriggers: function ($item, state) {
			var $triggers = ExpandCollapse._getItemTriggers($item).all;

			$triggers.each(ExpandCollapse._setStateTrigger(state));
		},

		_setStateTrigger: function (state) {
			return function (i, trigger) {
				var $trigger = $(trigger);

				var stateAttr = stateAttributes.expanded;
				var useCurrent = $trigger.data(dataSelectors.useCurrent);

				if (useCurrent) {
					stateAttr = stateAttributes.current;
				}

				if (state === States.OPENED) {
					$trigger.attr(stateAttr, 'true');
				} else if (state === States.CLOSED) {
					$trigger.attr(stateAttr, 'false');
				} else if (state === States.INITIAL) {
					$trigger.removeAttr(stateAttr);
				}
			};
		},

		_closeSet: function (set) {
			var $item;
			var $setItems;
			var $setItem;
			var i;

			if (set && set.jquery) {
				// If a jQuery object was passed through
				$item = set;
				set = $item.data(dataSelectors.set);
			}

			if (!set) {
				return;
			}

			set = set.split(setSeparator);
			if (set.length > 1) {
				for (i = 0; i < set.length; i++) {
					ExpandCollapse._closeSet(set[i]);
				}
				return;
			} else {
				set = set[0].trim();
			}

			publish(pubsubEvents.setClosed, [set]);

			$setItems = $('[data-' + dataSelectors.set + ']').filter(ExpandCollapse._isInSet(set));
			if ($item && $item.length) {
				// If an item has been passed through, it is being opened,
				// so only close other items in this set
				$setItems = $setItems.not($item);
			}

			for (i = 0; i < $setItems.length; i++) {
				$setItem = $setItems.eq(i);
				if (ExpandCollapse._getState($setItem) === States.OPENED) {
					ExpandCollapse._setState($setItem, States.CLOSED);
				}
			}
		},

		_isInSet: function (set) {
			return function (i, el) {
				var $item = $(el);
				var itemSet = $item.data(dataSelectors.set);

				itemSet = itemSet.split(setSeparator);

				return itemSet.indexOf(set) !== -1;
			};
		},

		_processOpen: function ($item) {
			// Run when an item enters the opened state

			var autofocusId;
			var autofocusDelay;
			var $autofocusTarget;

			// When this first opens, make sure any lazyload images inside are told to load
			if (!$item.data('lazyload-triggered')) {
				$item.find('.js-lazy-auto').trigger('appear');
			}
			$item.data('lazyload-triggered', true);

			autofocusId = $item.data(dataSelectors.autofocusId);
			if (autofocusId) {
				autofocusDelay = parseInt($item.data(dataSelectors.autofocusDelay), 10);
				$autofocusTarget = $('#' + autofocusId);

				if (autofocusDelay) {
					window.setTimeout(function () {
						$autofocusTarget[0].focus();
					}, autofocusDelay);
				} else {
					$autofocusTarget[0].focus();
				}
			}

			window.setTimeout(function () {
				$item.removeAttr('aria-live');
			}, 100);
		},

		_processClose: function ($item) {
			// Run when an item enters the closed state

			window.setTimeout(function () {
				$item.attr('aria-live', 'polite');
			}, 100);
		}
	};

	return {
		init: ExpandCollapse.init
	};
})($, activate, publish, subscribe);

export { ExpandCollapse };
