/* app/ui/video/load */

import $ from 'jquery';

import { VideoCommon } from 'App/video/common';
import { YouTube } from 'App/video/youtube';

var VideoLoad = {
	init: function() {
		VideoLoad._initVideoEvent();
	},

	_initVideoEvent: function() {
		$('.js-video-player').on('click', '.js-video', VideoLoad._loadVideo);
	},

	_loadVideo: function(event) {
		event.preventDefault();
		var thisVid = this;

		VideoCommon.init(thisVid);
		YouTube.init(thisVid);
	}
};

export { VideoLoad };
